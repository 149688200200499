import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/Layout/index.js";
import { Link } from "gatsby";
import SEO from "../components/SEO";
import Wide from "../components-styled/Wide";
import { Flex, Box } from "theme-ui";
import PictureSet from "../components/PictureSet";
import imgSet20 from "../imgsets/imgset20";
import logoExist from "../img/0400/logoEXIST.jpg";
import logoRFH from "../img/0400/logoRFH.png";
import logoSpoHo from "../img/0400/logoSpoHo.png";
import logoTHKoeln from "../img/0400/logoTHKoeln.png";
import logoUzK from "../img/0400/logoUzK.jpg";
import AlinaLey from "../img/0480/1x1/hgnc_Alina-Ley.jpg";
import DrClaudiaCombrink from "../img/0480/1x1/hgnc_Dr-Claudia-Combrink.jpg";
import DrMaritaMehlstaeble from "../img/0480/1x1/hgnc_Dr-Marita-Mehlstaeubl.jpg";
import DrSabineGrubenbecher from "../img/0480/1x1/hgnc_Dr-Sabine-Grubenbecher.jpg";
import DrSabineSternstein from "../img/0480/1x1/hgnc_Dr-Sabine-Sternstein.jpg";
import EstherDenOuden from "../img/0480/1x1/hgnc_Esther-den-Ouden.jpg";
import FloraCarlhoff from "../img/0480/1x1/hgnc_Flora-Carlhoff.jpg";
import LisaKoehler from "../img/0480/1x1/hgnc_Lisa-Koehler.jpg";
import MarcKley from "../img/0480/1x1/hgnc_Marc-Kley.jpg";
import ProfDrChristianSchwens from "../img/0480/1x1/hgnc_Prof-Dr-Christian-Schwens.jpg";
import ProfDrKaiBuehler from "../img/0480/1x1/hgnc_Prof-Dr-Kai-Buehler.jpg";
import ProfDrKaiThuerbach from "../img/0480/1x1/hgnc_Prof-Dr-Kai-Thuerbach.jpg";
import ProfDrKlausBecker from "../img/0480/1x1/hgnc_Prof-Dr-Klaus-Becker.jpg";
import ProfDrMarcProkop from "../img/0480/1x1/hgnc_Prof-Dr-Marc-Prokop.jpg";
import ProfDrRainerMinz from "../img/0480/1x1/hgnc_Prof-Dr-Rainer-Minz.jpg";
import ProfDrUlrichAnders from "../img/0480/1x1/hgnc_Prof-Dr-Ulrich-Anders.jpg";
import ProfJanReckerPhD from "../img/0480/1x1/hgnc_Prof-Jan-Recker-PhD.jpg";
import RamonaKoch from "../img/0480/1x1/hgnc_Ramona-Koch.jpg";
import SophiaHaski from "../img/0480/1x1/hgnc_Sophia-Haski.jpg";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Fit for Invest" keywords={[]} mdxType="SEO" />
    {
      /* ![""](../img/1920/4x1/32.b50.jpg) */
    }
    <Wide mdxType="Wide">
      <PictureSet imgSet={imgSet20} mdxType="PictureSet" />
    </Wide>
    <h1>{`Fit for Invest`}</h1>
    <Flex sx={{
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "center"
    }} mdxType="Flex">
  <Box sx={{
        width: "15%",
        px: 3
      }} mdxType="Box">
    <img src={logoTHKoeln} />
  </Box>
  <Box sx={{
        width: "20%",
        px: 3
      }} mdxType="Box">
    <img src={logoUzK} />
  </Box>
  <Box sx={{
        width: "32%",
        px: 3
      }} mdxType="Box">
    <img src={logoSpoHo} />
  </Box>
  <Box sx={{
        width: "27%",
        px: 3
      }} mdxType="Box">
    <img src={logoRFH} />
  </Box>
    </Flex>
    <h2>{`Aus den Hochschulen in die Wirtschaft`}</h2>
    <p>{`Mit »Fit for Invest« bündeln die vier größten Kölner Hochschulen
ihre Stärken und machen Startups „Investment-ready”, bringen
Know-how, Innovation und Kapital zusammen. Gleichzeitig wird
die Startup-Landschaft in der Region Köln nachhaltig gestärkt
und ein lebendiges Netzwerk geschaffen.`}</p>
    <p>{`Nach erfolgreicher Entwicklung wissensbasierter Produkte oder
Dienstleistungen stehen Gründer`}{`*`}{`innen schnell vor der nächsten
Herausforderung: Die Produktionskapazitäten müssen aufgebaut
und weiteres Wachstum finanziert werden. Die Maßnahmen
in »Fit for Invest« verbessern die „Investment Readiness“
von Gründungen. Gleichzeitig sensibilisieren und qualifizieren
sie Gründer`}{`*`}{`innen für eine erfolgreiche Kontaktaufnahme zu
Investor`}{`*`}{`innen sowie Business Angels und erhöhen die Anzahl an
Investitionsmöglichkeiten in der Region Köln.`}</p>
    <h3>{`Die Ziele im Überblick`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Steigerung der „Investment Readiness“ wissensbasierter Gründungen: Das Begleitprogramm macht Startups fit für die Kontaktaufnahme zu Investor`}{`*`}{`innen.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Aufbau und Stärkung des regionalen Investoren-Netzwerks: Kontakte knüpfen und interessante Investitionsmöglichkeiten kennenlernen.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Region Köln stärken: Unter Einbezug von Partnern aus Wirtschaft, Politik und Wissenschaft wird Köln attraktiv für Startups und Investoren.`}</p>
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1178px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUCBgn/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAdzHSd/VQJjP/8QAHBAAAgICAwAAAAAAAAAAAAAAAgQBAwAUBRES/9oACAEBAAEFAqFmqiIZ60uQxdOfZ0BIaRZ//8QAHREAAQIHAAAAAAAAAAAAAAAAAQARAjFhgaHB8P/aAAgBAwEBPwF4jPJfSag6y//EABcRAAMBAAAAAAAAAAAAAAAAAAABgfD/2gAIAQIBAT8Br1K9T//EACoQAAIBAgMECwAAAAAAAAAAAAECEQMhADFREhMi0yMzQkNhcXKUoqPw/9oACAEBAAY/AjUqxCqYC16tS5OZBRbKL42EqsSyPB2jY9l5XITbTTIz3fuqvJwG3z8MGOO/n0h/a4KgAT6o+LLfxnHXt9vOx//EABsQAQADAQEBAQAAAAAAAAAAAAERITEAUUHR/9oACAEBAAE/IatcNiISdQsmag58mczIwgxamWpIUc1vU39tz3xmtlJNCWoiiM+wrh2t30LmFw0sG+avP39e/9oADAMBAAIAAwAAABBQD//EAB4RAAEDBAMAAAAAAAAAAAAAAAEAETEhQWHhcZHw/9oACAEDAQE/EDYCcnR6DLEQm7+PNlf/xAAbEQABBAMAAAAAAAAAAAAAAAABABHh8EFhcf/aAAgBAgEBPxAscnRJOK6uSv/EABsQAQEBAAMBAQAAAAAAAAAAAAERIQAxQWGR/9oACAEBAAE/EEJadgWbq1NNAx0L6/gyXJIPnBRVqqpuvDqV+r5OJ3UdrL5sHUIZuUlWH3JDtrEYhU4ro46/RqV7W68//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "\"\"",
          "title": "\"\"",
          "src": "/static/d7fe8689df185980c25ae408e5c74944/a6e4d/ffiVerbundhochschulen.jpg",
          "srcSet": ["/static/d7fe8689df185980c25ae408e5c74944/a3e66/ffiVerbundhochschulen.jpg 480w", "/static/d7fe8689df185980c25ae408e5c74944/fb816/ffiVerbundhochschulen.jpg 960w", "/static/d7fe8689df185980c25ae408e5c74944/a6e4d/ffiVerbundhochschulen.jpg 1178w"],
          "sizes": "(max-width: 1178px) 100vw, 1178px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3>{`Teilprojekte der Verbundhochschulen`}</h3>
    <h3>{`Veranstaltungen und Begleitprogramme`}</h3>
    <h3>{`Geförderte Startups`}</h3>
    <h3>{`Unterstützer/ »Fit for Invest« Netzwerk`}</h3>
    <h3>{`Das »Fit for Invest« Kernteam`}</h3>
    <h4>{`Das Projektteam der Technischen Hochschule Köln`}</h4>
    <Flex sx={{
      flexWrap: "wrap",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      imageRendering: "pixelated"
    }} mdxType="Flex">
      <Box sx={{
        width: ["50%", "50%", "50%", "25%"]
      }} mdxType="Box">
  <Box sx={{
          width: "100%",
          px: 3,
          textAlign: "center"
        }} mdxType="Box">
    <img src={ProfDrKlausBecker} style={{
            width: "100%",
            borderRadius: "50%",
            marginBottom: "16px"
          }} />
          <p><strong parentName="p">{`Prof. Dr. Klaus Becker`}</strong>{`, `}<br />{` Verbundkoordinator`}</p>
  </Box>
      </Box>
      <Box sx={{
        width: ["50%", "50%", "50%", "25%"]
      }} mdxType="Box">
  <Box sx={{
          width: "100%",
          px: 3,
          textAlign: "center"
        }} mdxType="Box">
    <img src={ProfDrKaiThuerbach} style={{
            width: "100%",
            borderRadius: "50%",
            marginBottom: "16px"
          }} />
          <p><strong parentName="p">{`Prof. Dr. Kai Thürbach`}</strong><br />{`Projektleiter`}</p>
  </Box>
      </Box>
      <Box sx={{
        width: ["50%", "50%", "50%", "25%"]
      }} mdxType="Box">
  <Box sx={{
          width: "100%",
          px: 3,
          textAlign: "center"
        }} mdxType="Box">
    <img src={ProfDrMarcProkop} style={{
            width: "100%",
            borderRadius: "50%",
            marginBottom: "16px"
          }} />
          <p><strong parentName="p">{`Prof. Dr. Marc Prokop`}</strong><br />{`Projektleiter`}</p>
  </Box>
      </Box>
      <Box sx={{
        width: ["50%", "50%", "50%", "25%"]
      }} mdxType="Box">
  <Box sx={{
          width: "100%",
          px: 3,
          textAlign: "center"
        }} mdxType="Box">
    <img src={DrSabineGrubenbecher} style={{
            width: "100%",
            borderRadius: "50%",
            marginBottom: "16px"
          }} />
          <p><strong parentName="p">{`Dr. Sabine Grubenbecher`}</strong><br />{`Gründungsservice`}</p>
  </Box>
      </Box>
      <Box sx={{
        width: ["50%", "50%", "50%", "25%"]
      }} mdxType="Box">
  <Box sx={{
          width: "100%",
          px: 3,
          textAlign: "center"
        }} mdxType="Box">
    <img src={RamonaKoch} style={{
            width: "100%",
            borderRadius: "50%",
            marginBottom: "16px"
          }} />
          <p><strong parentName="p">{`Ramona Koch`}</strong><br />{`Projektteam`}</p>
  </Box>
      </Box>
      <Box sx={{
        width: ["50%", "50%", "50%", "25%"]
      }} mdxType="Box">
  <Box sx={{
          width: "100%",
          px: 3,
          textAlign: "center"
        }} mdxType="Box">
    <img src={EstherDenOuden} style={{
            width: "100%",
            borderRadius: "50%",
            marginBottom: "16px"
          }} />
          <p><strong parentName="p">{`Esther den Ouden`}</strong><br />{`Kommunikation & Marketing`}</p>
  </Box>
      </Box>
    </Flex>
    <h4>{`Projektteam der Universität zu Köln`}</h4>
    <Flex sx={{
      flexWrap: "wrap",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      imageRendering: "pixelated"
    }} mdxType="Flex">
      <Box sx={{
        width: ["50%", "50%", "50%", "25%"]
      }} mdxType="Box">
  <Box sx={{
          width: "100%",
          px: 3,
          textAlign: "center"
        }} mdxType="Box">
    <img src={ProfDrRainerMinz} style={{
            width: "100%",
            borderRadius: "50%",
            marginBottom: "16px"
          }} />
          <p><strong parentName="p">{`Prof. Dr. Rainer Minz`}</strong><br />{` Rektoratsbeauftragter`}</p>
  </Box>
      </Box>
      <Box sx={{
        width: ["50%", "50%", "50%", "25%"]
      }} mdxType="Box">
  <Box sx={{
          width: "100%",
          px: 3,
          textAlign: "center"
        }} mdxType="Box">
    <img src={ProfJanReckerPhD} style={{
            width: "100%",
            borderRadius: "50%",
            marginBottom: "16px"
          }} />
          <p><strong parentName="p">{`Prof. Jan Recker, PhD`}</strong><br />{` Projektleiter`}</p>
  </Box>
      </Box>
      <Box sx={{
        width: ["50%", "50%", "50%", "25%"]
      }} mdxType="Box">
  <Box sx={{
          width: "100%",
          px: 3,
          textAlign: "center"
        }} mdxType="Box">
    <img src={ProfDrChristianSchwens} style={{
            width: "100%",
            borderRadius: "50%",
            marginBottom: "16px"
          }} />
          <p><strong parentName="p">{`Prof. Dr. Christian Schwens, PhD`}</strong><br />{` Projektleiter`}</p>
  </Box>
      </Box>
      <Box sx={{
        width: ["50%", "50%", "50%", "25%"]
      }} mdxType="Box">
  <Box sx={{
          width: "100%",
          px: 3,
          textAlign: "center"
        }} mdxType="Box">
    <img src={MarcKley} style={{
            width: "100%",
            borderRadius: "50%",
            marginBottom: "16px"
          }} />
          <p><strong parentName="p">{`Marc Kley`}</strong><br />{` Geschäftsführer Gateway`}</p>
  </Box>
      </Box>
    </Flex>
    <h4>{`Projektteam der Deutschen Sporthochschule Köln`}</h4>
    <Flex sx={{
      flexWrap: "wrap",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      imageRendering: "pixelated"
    }} mdxType="Flex">
      <Box sx={{
        width: ["50%", "50%", "50%", "25%"]
      }} mdxType="Box">
  <Box sx={{
          width: "100%",
          px: 3,
          textAlign: "center"
        }} mdxType="Box">
    <img src={DrClaudiaCombrink} style={{
            width: "100%",
            borderRadius: "50%",
            marginBottom: "16px"
          }} />
          <p><strong parentName="p">{`Dr. Claudia Combrink`}</strong><br />{` Projektleiterin`}</p>
  </Box>
      </Box>
      <Box sx={{
        width: ["50%", "50%", "50%", "25%"]
      }} mdxType="Box">
  <Box sx={{
          width: "100%",
          px: 3,
          textAlign: "center"
        }} mdxType="Box">
    <img src={DrMaritaMehlstaeble} style={{
            width: "100%",
            borderRadius: "50%",
            marginBottom: "16px"
          }} />
          <p><strong parentName="p">{`Dr. Marita Mehlstaeubl`}</strong><br />{` Gründungsservice`}</p>
  </Box>
      </Box>
      <Box sx={{
        width: ["50%", "50%", "50%", "25%"]
      }} mdxType="Box">
  <Box sx={{
          width: "100%",
          px: 3,
          textAlign: "center"
        }} mdxType="Box">
    <img src={DrSabineSternstein} style={{
            width: "100%",
            borderRadius: "50%",
            marginBottom: "16px"
          }} />
          <p><strong parentName="p">{`Dr. Sabine Sternstein`}</strong><br />{` Projektteam`}</p>
  </Box>
      </Box>
      <Box sx={{
        width: ["50%", "50%", "50%", "25%"]
      }} mdxType="Box">
  <Box sx={{
          width: "100%",
          px: 3,
          textAlign: "center"
        }} mdxType="Box">
    <img src={SophiaHaski} style={{
            width: "100%",
            borderRadius: "50%",
            marginBottom: "16px"
          }} />
          <p><strong parentName="p">{`Sophia Haski`}</strong><br />{` Projektteam`}</p>
  </Box>
      </Box>
    </Flex>
    <h4>{`Projektteam der Rheinischen Fachhochschule Köln`}</h4>
    <Flex sx={{
      flexWrap: "wrap",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      imageRendering: "pixelated"
    }} mdxType="Flex">
      <Box sx={{
        width: ["50%", "50%", "50%", "25%"]
      }} mdxType="Box">
  <Box sx={{
          width: "100%",
          px: 3,
          textAlign: "center"
        }} mdxType="Box">
    <img src={ProfDrKaiBuehler} style={{
            width: "100%",
            borderRadius: "50%",
            marginBottom: "16px"
          }} />
          <p><strong parentName="p">{`Prof. Dr. Kai Bühler`}</strong><br />{`Projektleiter`}</p>
  </Box>
      </Box>
      <Box sx={{
        width: ["50%", "50%", "50%", "25%"]
      }} mdxType="Box">
  <Box sx={{
          width: "100%",
          px: 3,
          textAlign: "center"
        }} mdxType="Box">
    <img src={AlinaLey} style={{
            width: "100%",
            borderRadius: "50%",
            marginBottom: "16px"
          }} />
          <p><strong parentName="p">{`Alina Ley`}</strong><br />{` Gründungsservice`}</p>
  </Box>
      </Box>
      <Box sx={{
        width: ["50%", "50%", "50%", "25%"]
      }} mdxType="Box">
  <Box sx={{
          width: "100%",
          px: 3,
          textAlign: "center"
        }} mdxType="Box">
    <img src={LisaKoehler} style={{
            width: "100%",
            borderRadius: "50%",
            marginBottom: "16px"
          }} />
          <p><strong parentName="p">{`Lisa Köhler`}</strong><br />{` Projektteam`}</p>
  </Box>
      </Box>
    </Flex>
    <h4>{`Das Team der Unterstützer und Beiräte`}</h4>
    <Flex sx={{
      flexWrap: "wrap",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      imageRendering: "pixelated"
    }} mdxType="Flex">
      <Box sx={{
        width: ["50%", "50%", "50%", "25%"]
      }} mdxType="Box">
  <Box sx={{
          width: "100%",
          px: 3,
          textAlign: "center"
        }} mdxType="Box">
    <img src={ProfDrUlrichAnders} style={{
            width: "100%",
            borderRadius: "50%",
            marginBottom: "16px"
          }} />
          <p><strong parentName="p">{`Prof. Dr. Ulrich Anders`}</strong><br />{` Geschäftsführer hgnc`}</p>
  </Box>
      </Box>
      <Box sx={{
        width: ["50%", "50%", "50%", "25%"]
      }} mdxType="Box">
  <Box sx={{
          width: "100%",
          px: 3,
          textAlign: "center"
        }} mdxType="Box">
    <img src={FloraCarlhoff} style={{
            width: "100%",
            borderRadius: "50%",
            marginBottom: "16px"
          }} />
          <p><strong parentName="p">{`Flora Carlhoff`}</strong><br />{` Geschäftsstelle hgnc`}</p>
  </Box>
      </Box>
    </Flex>
    <h3>{`Pressekontakt`}</h3>
    <p>{`Esther den Ouden`}<br />{`
Kommunikation & Marketing`}<br /></p>
    <p>{`0221-8275-5278`}<br />{`
`}<a parentName="p" {...{
        "href": "mailto:kontakt@fitforinvest.de",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`kontakt@fitforinvest.de`}</a></p>
    <Flex sx={{
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "center"
    }} mdxType="Flex">
  <Box sx={{
        width: ["50%", "50%", "50%", "25%"]
      }} mdxType="Box">
    <img src={logoExist} />
  </Box>
    </Flex>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      